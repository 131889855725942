.rooms-total
  background-color: $blueColor
  color: $whiteColor
  padding: 15px
  border-radius: 15px
  text-align: right
  font-size: $smallSize
  span
    display: block
    font-size: $standartSize
    font-weight: 700
    margin-bottom: 15px
  li
    margin-bottom: 8px
.rooms-totlal-price
  font-size: $largeSize
  font-weight: 700
  margin: 15px 0
