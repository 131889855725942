.home-simple-text
  font-size: 24px
  // padding-bottom: 90px
  background-color: $blueColor
  color: $whiteColor
  h2
    font-size: 40px
    margin: 0 0 30px
    color: inherit
@media (max-width: 576px)
  .home-simple-text
    padding: 0
  .home-simple-text h2
    font-size: 36px
    margin: 0 0 5px
  .home-simple-text
    .text
      font-size: 14px
