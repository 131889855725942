.gender-btns
	display: flex
	gap: 14px
	.btn
		width: 50%
		border: 1px solid $blueColor
		&:hover,
		&.active
			color: $whiteColor
			background-color: $blueColor
	.man-btn
		i
			@include icoEl(20px, 16px, '../images/icons-app/man-black.svg')
		&:hover,
		&.active
		i
			background-image: url('../images/icons-app/man-white.svg')
	.woman-btn
		i
			@include icoEl(20px, 16px, '../images/icons-app/woman-black.svg')
		&:hover,
		&.active
		i
			background-image: url('../images/icons-app/woman-white.svg')
.popup-container
	.link--back
		position: absolute
		left: 0
		top: 0
		margin: 15px

	.reg-btn
		color: $blackColor
		&:hover
			color: $blueColor
			border-color: $blueColor
	h4
		margin-bottom: 15px
	.btn-reg-container
		margin-top: 30px
.err-hint
	color: $redColor
	text-align: center
	margin: 0 0 8px
.reg-end
	text-align: center
	margin: 0 0 30px
	h3
		color: $blueColor
		font-size: $standartSize
		margin: 30px 0 15px
		line-height: 1.3
.reg-end-ico
	@include icoEl(130px,100px, "../images/icons-app/reg-end.svg")
.simle-link
	text-align: center
	margin-top: 25px
	a
		text-decoration: none
.checkbox-custom
	font-size: 14px
	position: relative
	padding-left: 30px
	margin-bottom: 20px
	a
		color: $blueColor
		display: inline
	span
		@include positionElement(20px,20px,0,0)
		border: 1px solid $blackColor
		border-radius: 100%
		cursor: pointer
		&:hover
			border-color: $blueColor
		&.active
			&::after
				content: ""
				@include positionElement(12px,12px, 3px, 3px)
				background-color: $blueColor
				border-radius: 100%
.checkbox-custom-line
	margin-bottom: 4px
