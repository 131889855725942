.dark-theme
  background-color: #0B132B
  header
    background: none
    .nav-header
      a
        color: $whiteColor
        &:hover
          color: $blueColor
    .btn-in
      background-color: $blueColor
      border-color: $blueColor
      color: $whiteColor
      &:hover
        background-color: $whiteColor
        color: $blueColor
        border-color: $whiteColor
  .goals-users-tag
    background-color: #0B132B
  .logo-dark
    display: none
  .logo-white
    display: block
  .cabinet-page label
    color: $whiteColor
  .btn--white
    background-color: $blueColor
    color: $whiteColor
    border-color: $blueColor
  .border-container,
  .border-tab,
  .controls-panel-popup,
  .controls-panel-popup .panel-deg
    border-color: $whiteColor
    background-color: #0B132B
    color: $whiteColor

  .border-tab.active
    background-color: $whiteColor
    color: $blackColor
  .switch-input
    border-color: 1px solid $whiteColor
  .tags-popup,
  .btn-save-outer
    background-color: $blackColor
  .main-home
    .main-home-img
      background-image: url("../images/main-bg-black.jpg")
    .btn--black
      background-color: $blueColor
      color: $whiteColor
      border-color: $blueColor
      &:hover
        background-color: $whiteColor
        border-color: $whiteColor
        color: $blueColor
  .home-simple-text
    background-color: $blackColor
  .partners-home
    background-color: $blackColor
    .partners-item
  .input-box label,
  .tags-add-container h3,
  .user-top-info--view h2,
  .rooms-item-date,
  .messages-date,
  .chat-invite-container h3,
  .like-hint,
  .user-location h3,
  .tags-container h3,
  .total-count,
  .horizontal-slider .range-thumb,
  .border-container h3,
  .bests-panel a,
  .hotels-name,
  .hotels-price
    color: $whiteColor
  .tag
    background-color: $blackColor
    color: $whiteColor
    border-color: $whiteColor
    &.active
      background-color: $blueColor
      border-color: $blueColor
  .settings-tabs-container,
  .invite-ico,
  .btn-trash
    background-color: $blackColor
    border-color: $whiteColor
  .man-ico
    background-image: url("../images/icons-app/man-white.svg")
  .woman-ico
    background-image: url("../images/icons-app/woman-white.svg")
  .marker-ico
    background-image: url("../images/icons-app/marker-white.svg")
  .calendar-ico
    background-image: url("../images/icons-app/calendar-white.svg")
  .portfel-ico
    background-image: url("../images/icons-app/tags/portfel-white.svg")
  .celi-ico
    background-image: url("../images/icons-app/tags/celi-white.svg")
  .orientacia-ico
    background-image: url("../images/icons-app/tags/orientacia-white.svg")
  .zodiak-ico
    background-image: url("../images/icons-app/tags/zodiak-white.svg")
  .btn-hamburger
    background-image: url("../images/icons/hamburger-white.svg")
  .bokal-ico
    background-image: url("../images/icons-app/tags/bokal-white.svg")
  .padushka-ico
    background-image: url("../images/icons-app/tags/padushka-white.svg")
  .plag-ico
    background-image: url("../images/icons-app/tags/plag-white.svg")
  .pribor-ico
    background-image: url("../images/icons-app/tags/pribor-white.svg")
  .partners-black
    display: block
  .partners-white
    display: none
  .messages-box--answer
    background-color: rgba($whiteColor, 0.1)
    color: $whiteColor
  .settings-tab
    color: $whiteColor
  .settings-tab:hover,
  .settings-tab.active
    color: $blackColor
.logo-white
  display: none
.partners-black
  display: none
@media (max-width: 576px)
  .dark-theme
    .main-home
      .main-home-img
        background-image: url("../images/main-bg-mobile-black.jpg")

        color: $blueColor
