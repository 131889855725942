.hotel-tabs
  background-color: $grayColorSecond
  padding: 15px
  font-size: $mediumSize
  font-weight: 700
  position: sticky
  width: 100%
  top: 0
  z-index: 3
  ul
    display: flex
    justify-content: space-between

  li
    display: inline-block
    cursor: pointer
    &:hover
      color: $blueColor
