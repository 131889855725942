.settings-tabs-container
  display: flex
  justify-content: space-between
  font-weight: 700
  cursor: pointer
  border: 1px solid $grayColor
  border-bottom: 0
  border-top-right-radius: 30px
  position: relative
  overflow: hidden
.settings-tab
  border-left: 1px solid $grayColor
  width: 34%
  text-align: center
  padding: 15px
  &:first-child
    border: 0
  &.active,
  &:hover
    background-color: $grayColorSecond
.link-delete-account
  color: $grayColor
  font-weight: 700
  display: inline-block
  margin-left: 30px
  cursor: pointer
  &:hover
    color: $blackColor
@media (max-width: 576px)
  .settings-tabs-container
    display: block
    border-radius: 0
  .settings-tab
    width: 100%
    border: 0
