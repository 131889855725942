.page-404
  text-align: center
  margin: 0 auto
  .btn
    width: 180px
.page-404-img
  display: inline-flex
  font-size: 80px
  font-weight: 700
  color: $whiteColor
  text-shadow: 0 15px 30px rgba(0 ,0 ,0 , 23%)
  h1
    font-size: inherit
    color: inherit
    margin: auto
.page-404-text
  margin: 30px 0
