/*-custom-select-*/

.custom-select
	cursor: pointer
	position: relative
	padding: 7px 15px
	input
		@include positionElement(0,0,0,0)
		overflow: hidden
	ul

		@include positionElement(100%, auto, 0, calc(100% + 8px), left, top, 2)

		opacity: 0
		visibility: hidden
		overflow-y: auto
		background-color: $whiteColor
		box-shadow: 0 0 6px rgb($blackColor, 0.4 )
		border-radius: 15px
		width: 100%
		max-height: 180px
		border-top: 0

		li
			padding: 5px 14px
			cursor: pointer
			transition: ease-out 0.25s
			margin: 0
			&:hover
				color: $blueColor

	&.active
		z-index: 3
		ul
			visibility: visible
			opacity: 1

		i
			transform: translateY(-50%) rotate(180deg)

	span
		position: relative
		z-index: 2

		vertical-align: middle
	i
		@include bgImageEl("../images/arrows/carret-down-gray.svg", 36px)
		@include positionElement(36px, 36px, 5px, 50%, right, top, 2)
		transform: translateY(-50%)

/*-custom-select-*/
