.room-item
  border: 1px solid $grayColorSecond
  border-radius: 15px
  margin-bottom: 15px
  // padding: 15px
  .main-grid
    padding: 0
    gap: 15px
.room-head-img
  width: 130px
  position: relative
  overflow: hidden
  border-radius: 15px
  font-size: 0
  img
    width: 100%
.room-item-head
  display: flex
  gap: 30px
  padding: 15px
  h3
    font-size: $mediumSize
    color: $blueColorSecond
    b
      margin-right: 15px
    span
      font-weight: 400
      font-size: $smallSize
.room-head-adv

.room-adv-item
  background-color: $grayColorSecond
  border-radius: 4px
  font-size: $smallSize
  display: inline-block
  padding: 2px 8px
  margin: 4px
  img
    width: 10px
    margin-right: 8px

.room-item-variant
  border-top: 1px solid $grayColorSecond
  padding: 15px
.room-variant-services
  font-size: $smallSize
  img
    width: 18px
    margin-right: 8px
  span,
  img
    vertical-align: middle
  li
    margin-bottom: 8px
.room-variant-price
  font-size: $largeSize
  font-weight: 700
  text-align: right
@media (max-width: 576px)
  .room-head-img
    width: 100%
    margin-bottom: 8px
  .room-item-head
    display: block
  .room-head-info
    h3
      margin-bottom: 8px
