.switch-container
  span,
  .switch-input
    vertical-align: middle
  input
    height: 0
    opacity: 0
    position: absolute
    width: 0
.switch-input
  border: 1px solid $blueColor
  width: 50px
  height: 25px
  border-radius: 15px
  cursor: pointer
  display: inline-block
  margin: 7px 9px
  font-size: $smallSize
  span,i,a
    vertical-align: middle
    text-decoration: none
  i, a
    position: relative
    margin: 2px
    display: block
    width: calc(50% - 5px)
    height: calc(100% - 4px)
    background-color: $blueColor
    border-radius: 100%
    left: 0
    transition: ease-out 0.25s

.switch-input--active
  i,a
    left: 50%
