.search-select
	padding: 0
	border: 0
	span
		display: inline-block
		width: 100%
		padding: 8px 15px 7px
.search-field-container
	position: relative
	// padding-top: 32px
	// height: 36px

	input
		width: 100%
		position: relative
		height: auto
		padding-right: 30px
		// border: 0
	em
		@include positionElement(24px,24px,5px,7px,right,top,2)
		@include bgImageEl("../images/icons/loop-gray.svg")
.search-choises
	em
		background-size: 12px
		background-image: url("../images/icons/close-gray.svg")
		cursor: pointer
		&:hover
			background-image: url("../images/icons/close-black.svg")
