
.border-search-hotels
  .input-box
    margin-bottom: 25px
  .geo-input-box
    margin-bottom: 0
  .btn-container
    text-align: left
    margin-top: 15px
    .btn
      width: 190px
      margin: 0
.border-users-mini
  .input-box
    margin-bottom: 15px
  .box-range
    margin-bottom: 45px
.hotels-search-panel
  .search-select
    li
      display: none
    .hotels
      display: block
  .main-grid
    row-gap: 0
  .btn-container
    margin-top: 0
@media (max-width: 992px)
  .border-search-hotels .geo-input-box
    display: none

@media (max-width: 576px)
  .geo-input-box
    display: none
  // .border-search-hotels
  //   .border-container
  //     border: 0
  //     padding: 0
  //     margin: 0
  //   .show-filter-mobile-container
  //     display: none
  //   .border-tabs-container
  //     display: flex
  //     border-bottom: 1px solid $grayColor
  //     margin-bottom: 15px
  //     gap: 4px
  //     width: calc(100% - 0px)
  // .hotel-user-catalog
  //   gap: 0
