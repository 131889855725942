.chat
	padding: 0
	.main-grid
		gap: 15px
.chat-form
	padding: 15px 30px 15px 0
	textarea
		height: 140px
		border-radius: 15px
		font-size: $standartSize
		padding-right: 50px

	.form-container
		font-size: 0
		position: relative
		text-align: center
	.btn
		width: 180px
	.btn-container
		text-align: center
	.add-file
		@include positionElement(30px,30px, 0, 0, right, bottom, 2)
		cursor: pointer
		margin: 15px
		@include bgImageEl("../images/icons-app/add-photo.svg")
		&:hover
			opacity: 0.8

	.file-decorate
		border: 0
		@include positionElement(30px,30px, 0, -55px, right, top, 2)
		cursor: pointer
		margin: 0 15px
		border-radius: 0
		left: auto
		@include bgImageEl("../images/icons-app/add-photo.svg")
		opacity: 0.6
		&:hover
			opacity: 1
		i,span
			display: none
	.file-input-container
		margin-top: 15px
		text-align-last: left
		padding-bottom: 15px
	.dragdrop-file-item
		width: 120px
		height: 90px
		position: relative
		border: 1px solid $blueColor
		border-radius: 6px
		img
			width: 100%
		// height: 60px
	.input-file
		display: none
.invite-ico-box
	display: inline-block
.chat-invite-container
	margin: 0px 0 15px
	text-align: center
	h3
		font-size: 16px
		font-weight: 400
		margin: 0 0 8px
		color: $grayColor
	.btn
		font-size: 16px
	i
		width: 28px
		height: 28px
.invite-ico
	display: inline-block
	width: 50px
	height: 50px
	border-radius: 100%
	border: 1px solid $grayColor
	text-align: center
	display: inline-flex
	cursor: pointer
	position: relative
	margin: 0 4px
	i
		margin: auto
		opacity: 0.6

	&:hover
		opacity: 0.6
		span
			visibility: visible
			opacity: 1
	span
		position: absolute
		background-color: $blackColor
		padding: 1px 3px
		color: $whiteColor
		right: calc(100% + 4px)
		top: 0
		font-size: $smallSize
		width: 180px
		text-align: center
		border-radius: 4px
		opacity: 0
		visibility: hidden
		i
			display: none
