.page-why
  h1
    font-size: 40px
    color: $blackColor
    max-width: 520px
    margin: 0 auto 30px
    span
      color: $blueColor

.why-top
  text-align: center
  img
    width: 100%
    max-width: 640px

.img-why
  margin: 90px 0

.why-body
  img
    width: 100%
  h3
    color: $blackColor
  p
    margin: 0 0 30px
  .btn-container
    text-align: center
    margin-bottom: 30px
    .btn
      width: 200px
