*
	box-sizing: border-box

body,
html
	font-family: 'Gilroy', sans-serif
	color: $blackColor
	font-size: $standartSize
	min-width: 320px
	min-height: 640px
	max-width: 1920px
	margin: 0 auto
	padding: 0
	position: relative
	font-weight: 400
	line-height: 1.5

	// background-color: $whiteColorSecond
	background-color: $whiteColor

a
	color: inherit
	display: inline-block

	&:hover

.ln,
.ln li
	padding: 0
	margin: 0
	list-style: none

.custom-disk
	padding: 0
	li
		padding: 0
		list-style: none
		padding-left: 15px
		position: relative

		&:before
			content: ""
			@include positionElement(4px, 4px, 0, 8px)
			background: $blackColor
			border-radius: 100%

.flex-align
	display: flex
	align-items: center
	justify-content: center

.vertical-align
	display: flex
	height: 100%
	position: relative
	*
		margin: auto 0

.img-cover
	position: relative
	background-size: cover
	background-position: center
	background-repeat: no-repeat
	// overflow: hidden
	img
		visibility: hidden
		object-fit: cover
		@include positionElement(100%, 100%, 0, 0)
.img-use-bg
	@include bgImageEl("../images/stub-ico.svg", cover)
	background-position: center 20%
h1,
h2,
h3,
h4,
h5,
h6
	margin: 0
	padding: 0
	color: $blueColor
	font-weight: 800
h1
	font-size: 68px
	line-height: 1.2
h2
	font-size: 48px
h3
	font-size: 28px
@media (max-width: 992px)
	body,
	html
		font-size: 14px
@media (max-width: 768px)
	h1
		font-size: 48px
	h2
		font-size: 28px
	h3
		font-size: 24px
@media (max-width: 576px)
	h1
		font-size: 32px
