.main-home
  height: 100vh
  min-height: 620px
  background-color: $blueColor
  position: relative
  color: $whiteColor
  text-align: center
  h1
    margin: 0
    margin-bottom: 30px
    color: inherit
    font-size: 130px
    text-shadow: 0px 0px 40px #0B132BCC
  .main-home-img
    @include positionElement(100%,100%,0,0)
    @include bgImageEl("../images/main-bg.jpg", cover)
  .btn
    width: 280px
  .main-home-content
    position: relative
    display: flex
    flex-direction: column
    height: 100%
    justify-content: space-evenly
.btn-in-mobile
  display: none
@media (max-width: 576px)
  .main-home
    h1
      font-size: 64px
      line-height: 54px
    .btn
      width: 257px
      font-size: 20px
      padding: 4px
    .main-home-img
      background-image: url("../images/main-bg-mobile.jpg")
  .btn-in-mobile
    display: inline-block
    margin-top: 15px
@media (max-width: 375px)
  .main-home h1
    font-size: 58px
