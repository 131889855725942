
$microSize: 12px

$smallSize: 14px

$standartSize: 16px

$mediumSize: 18px

$largeSize: 24px

$hugeSize: 48px
