.hotel-booking-info
  display: flex
  .hotels-raiting-container
    padding: 14px
.hotel-booking
  .hotel-main-bottom
    h3
      font-size: $smallSize
    li
      font-size: $smallSize
      width: 100%
  .main-grid
    padding: 0
.hotel-booking-img
  width: 40%
  height: 200px
.hotel-booking-details
  width: 60%
  padding: 30px
.hotel-booking-details-name
  margin-bottom: 15px
  font-size: $largeSize
  b
    display: block
  span
    font-size: $mediumSize
.hotel-booking-details-date
  display: inline-block
  width: 49%
  b
    display: inline-block
    margin-right: 15px

.hotel-booking-total
  display: flex
  border-top: 1px solid $grayColorSecond
  justify-content: space-between
  font-size: $largeSize
  span
    display: inline-block
    padding-top: 19px
  b
    font-size: $hugeSize
.form-booking
  margin-bottom: 30px
