.cabinet-page
	h3, label
		font-size: 20px
		color: $blueColorSecond
		text-transform: initial
		font-weight: 700
		margin-bottom: 8px
	.user-top-info--view
		gap: 15px
		h2
			width: 50%
			font-size: 30px
			margin: -10px 0 0
		.input-box
			width: 25%
	.travel-item
		padding-top: 30px
	.personal-info
		li
			margin: 0 0 12px
.user-top-info
	display: flex
	gap: 30px
.verification-hint
	font-size: 14px
	color: $blueColor
.err-date-cabinet
	font-size: 12px
	color: $redColor
	font-weight: 700
	line-height: 1.2
	display: inline-block
	margin-top: 8px
.account-main
	.file-decorate
		text-align: center
		span
			padding: 0
.tags-add-container
	display: flex

	.tags-container,
	.tags-add-input
		width: 50%
	.tags-add-hint
		margin-bottom: 8px

	&.special
		.tags-search
			i
				display: none
	.btn
		width: 240px
.gender-input-box
	text-align: right
.tags-search
	i
		@include bgImageEl("../images/arrows/carret-down-gray.svg", 36px)
		@include positionElement(36px, 36px, 5px, 50%, right, top, 2)
		transform: translateY(-50%)
	&.active
		.tags-popup
			opacity: 1
			visibility: visible
		i
			transform: translateY(-50%) rotate(180deg)
.tags-addted
	i
		i
			vertical-align: text-bottom
.tags-popup
	overflow-y: auto
	background-color: $whiteColor
	box-shadow: 0 0 6px rgb($blackColor, 0.4 )
	border-radius: 15px
	max-height: 180px
	border-top: 0
	padding: 15px
	opacity: 0
	visibility: hidden
	transition: ease-out 0.25s
	@include positionElement(100%,auto,0,100%,left,top, 2)
	i
		display: none
	li
		cursor: pointer
		padding-bottom: 8px
		&:hover
			color: $blueColor
		&:last-child
			margin: 0

.border-delimetr
	width: 100%
	height: 1px
	background-color: $blackColor

$userTagSize: 18px
.portfel-ico
	@include icoEl($userTagSize, $userTagSize, "../images/icons-app/tags/portfel.svg")
.zodiak-ico
	@include icoEl($userTagSize, $userTagSize, "../images/icons-app/tags/zodiak.svg")
.celi-ico
	@include icoEl($userTagSize, $userTagSize, "../images/icons-app/tags/celi.svg")
.orientacia-ico
	@include icoEl($userTagSize, $userTagSize, "../images/icons-app/tags/orientacia.svg")

.personal-info
	li
		display: flex
	i,b
		vertical-align: middle
	i
		margin-right: 8px
	.personal-info-name
		width: 140px
		padding: 7px 0
	.personal-info-value
		width: calc(100% - 140px)

.user-description
	textarea
		height: 150px
.hit-img-null
	color: #ed1834
	text-align: center
	font-weight: 700
	font-size: 12px
	max-width: 200px
	margin: -10px auto -60px
.btn-save-outer
	position: fixed
	width: 100%
	left: 0
	bottom: -48px
	z-index: 2
	background: #ffffff
	padding: 5px
	box-shadow: 0 -5px 10px rgba(11, 19, 43, 0.05)
	transition: ease-out 0.5s
	span
		margin-right: 30px
	&.active
		bottom: 0

.slider-cabinet-mobile
	display: none

@media (max-width: 992px)

	.tags-add-container
		display: block
		.tags-container,
		.tags-add-input
			width: 100%
@media (max-width: 576px)
	.slider-cabinet-desktop
		display: none
	.slider-cabinet-mobile
		display: block
	.cabinet-page
		.border-tabs-container-outer
			display: none
		.border-container
			border: 0
			padding: 0
		.user-top-info--view
			h2
				width: 100%
			.input-box
				width: 49%
				display: inline-block
				vertical-align: top
		.tags-add-container
			display: block
			.tags-container,
			.tags-add-input
				width: 100%
			.btn
				width: 100%
		.personal-info
			padding-top: 30px

		.tiny-account
			.tns-controls
				left: 5px
				width: calc(100% - 5px)
			.tiny-account-img
				height: 190px

		.btn-save-outer
			text-align: center
			padding: 15px
			bottom: auto
			top: 100dvh
			span
				margin: 0
			.btn
				width: 100%
			&.active
				top: calc(100dvh - 148px)
		.hit-img-null
			margin: 0px auto 0px
	.border-tabs-container-cabinet
		display: none
