/*password-field start*/
.input-password
  position: relative
  input
    padding-right: 45px
  i
    @include positionElement(24px,24px,0,0,right)
    margin: 8px
    @include bgImageEl("../images/icons/unview-black.svg")
    cursor: pointer

    &:hover
      background-image: url("../images/icons/unview-gray.svg")
  &.view-pass
    i
      background-image: url("../images/icons/view-black.svg")
      &:hover
        background-image: url("../images/icons/view-gray.svg")
/*password-field end*/
