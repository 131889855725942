.tiny-account
	position: relative
	img
		@include positionElement(100%,100%,0,0)
		object-fit: cover
		object-position: center 20%
	.tns-controls
		left: 15px
		width: calc(100% - 30px)
		top: 50%
		margin-top: -24px
	[data-controls=prev],
	[data-controls=next]
		background: none
		border: 0
		display: block
	[data-controls=prev]
		@include bgImageEl("../images/arrows/carret-left-white.svg",75px)
	[data-controls=next]
		@include bgImageEl("../images/arrows/carret-right-white.svg",75px)
.tiny-account-shadow
	@include positionElement(100%,100%,0,0,left,top,2)
	box-shadow: inset 0px -50px 80px rgb(0 0 0 / 22%)
.tiny-account-img
	height: 297px
	position: relative
	.delete-img-dragdrop
		top: 30px
.tiny-account
	border-radius: 20px
	overflow: hidden
	margin: 0 0 15px
.tiny-account-stub
	height: 183px
	margin: 0 0 15px

	border-radius: 30px
	position: relative
	text-align: center
	span
		display: block
		font-size: 16px
		color: $blueColor
		max-width: 160px
		margin: 60px auto 15px
	i
		display: inline-block
		width: 150px
		height: calc(100% - 70px)
		@include bgImageEl("../images/tiny-stub.svg")
.hint-tiny-file
	font-size: 12px
	i
		font-style: normal

.dragdrop-uploaded-tiny
	.tiny-account-item
		height: 90px
		width: 120px
		display: inline-block
		font-size: 0
		margin-right: 15px
		position: relative
		img
			@include positionElement(100%,100%,0,0)
			object-fit: cover
			object-position: center 20%
	.tiny-account-img
		width: 100%
		height: 100%
		border-radius: 15px
		border: 1px solid $blueColor
		overflow: hidden
		position: relative
	.delete-img-dragdrop
		top: 17px
		right: 17px
@media (max-width: 568px)

	.tiny-account-stub
		height: 127px
		span
			font-size: 14px
			color: #00AEEF
			max-width: none
			margin: 0
		i
			width: 70px
			height: 130px
	.dragdrop-uploaded-tiny
		.delete-img-dragdrop
			display: none
