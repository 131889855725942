$blackColor: #0B132B

$grayColor: #8c95a6
// rgba(58, 80, 107, 0.6)
// $grayColor: #ebedf0

$grayColorSecond: #ebedf0

$grayColorThree: #8c95a6

$whiteColor: #ffffff

$whiteColorSecond: #f3f3f3

$redColor: #ed1834

$orangeColor: #fba72a

$greenColor: #00F35B

$greenColorSecond: #03ac9f

$blueColor: #00AEEF
$blueColorSecond: #3A506B

$goldColor: #C19F56
