.btn
	background: none
	text-decoration: none
	border: 1px solid
	border-color: $whiteColor
	border-radius: 30px
	background-color: $whiteColor
	padding: 6px 12px
	display: inline-block
	cursor: pointer
	text-align: center
	font: inherit
	font-size: 16px
	font-weight: 700
	transition: ease-out 0.25s
	width: 160px
	i,em
		font-style: normal

.btn--blue
	background-color: $blueColor
	border-color: $blueColor
	color: $whiteColor
	&:hover
		background: $blueColorSecond
		color: $whiteColor
		border-color: $blueColorSecond
.btn-white
	background-color: $whiteColor
	border-color: $whiteColor
	color: $blackColor
	&:hover
		background: $blackColor
		color: $whiteColor
		border-color: $blackColor

.btn--blue-border
	background: $whiteColor
	border-color: $blueColor
	color: $blueColor
	&:hover
		background: $blueColor
		color: $whiteColor
		border-color: $blueColor

.btn--black
	background-color: $blackColor
	border-color: $blackColor
	color: $whiteColor
	&:hover
		background: $blueColor
		color: $whiteColor
		border-color: $blueColor
.btn--black-border
	background-color: $whiteColor
	border-color: $blackColor
	color: $blackColor
	&:hover
		background: $blackColor
		color: $whiteColor
		border-color: $blackColor
.btn--gray
	background-color: $blackColor
	border-color: $blackColor
	color: $whiteColor
	&:hover
		background: $blueColor
		color: $whiteColor
		border-color: $blueColor
.btn--gray-border
	background-color: $whiteColor
	border-color: $grayColorThree
	color: $grayColorThree
	&:hover
		background: $blueColor
		color: $whiteColor
		border-color: $blueColor

.btn--white
	background-color: $whiteColor
	border-color: $whiteColor
	color: $blackColor
	&:hover
		background: $blackColor
		border-color: $blackColor
		color: $whiteColor
.btn--exit
	background-color: $whiteColor
	border-color: $whiteColor
	color: $blueColor
	&:hover
		background: $blueColor
		border-color: $blueColor
		color: $whiteColor

.btn--white-border
	background: none
	border-color: $whiteColor
	color: $whiteColor
	&:hover
		background: $whiteColor
		border-color: $whiteColor
		color: $blackColor
