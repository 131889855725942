.preloader-container
	position: relative
	height: 60px

.preloader-catalog
	text-align: center
	max-width: 540px
	h2
		color: $blackColor
		font-size: 20px
.preloader
	overflow: hidden

	border-radius: 50%
	background: $blueColor
	background: linear-gradient(to right, $blueColor 10%, rgba(101,171,248, 0) 42%)
	animation: loadAnimate 1.4s infinite linear
	@include positionElement(40px,40px,50%,50%,left,top,2)
	margin-top: -20px
	margin-left: -20px
	transform: translateX(0%) translateY(0%) translateZ(0)
	&:before
		width: 50%
		height: 50%
		background: $blueColor
		border-radius: 100% 0 0 0
		position: absolute
		top: 0
		left: 0
		content: ''

	&:after
		background: $whiteColor
		width: 75%
		height: 75%
		border-radius: 50%
		content: ''
		margin: auto
		position: absolute
		top: 0
		left: 0
		bottom: 0
		right: 0

@keyframes loadAnimate
	0%
		transform: rotate(0deg)

	100%
		transform: rotate(360deg)
