#tns1-iw
  margin: 0 !important
.hotel-images
  position: relative
  height: auto
  margin-bottom: 30px
  img
    width: 100%
  .tns-outer
    position: relative
  .tns-controls
    bottom: 60px

.images-item-container,
.images-item-grid
  position: relative
  height: 450px
  img
    @include positionElement(100%,100%,0,0)
    object-fit: cover
.images-item-container
  position: relative
  overflow: hidden
  border-radius: 15px

.images-item-grid
  display: grid
  grid-template-columns: repeat(2,1fr)
  gap: 15px
.images-item-cell
  position: relative
  overflow: hidden
  border-radius: 15px
.hotel-main-top
  padding-bottom: 15px
  h1,
  .hotels-raiting-num
    display: inline-block
    vertical-align: middle
    margin-right: 30px
  h1
    padding: 0
    font-size: 48px

  .star.active
    background-image: url('../images/icons/star-fill-blue.svg')
  .tag
    margin: 0
    margin-left: 30px

.hotel-address
  text-align: right
  margin-top: 8px
  margin-bottom: 15px
  i
    margin-right: 15px

.hotel-from-price
  text-align: right
  font-weight: 700
  font-size: $mediumSize

.hotel-main-bottom
  padding-bottom: 30px
  h3
    font-size: $mediumSize
    color: $blueColorSecond
    margin-bottom: 8px
  a
    text-decoration: none
    &:hover
      color: $blueColor
  li
    margin-bottom: 8px
.hotel-services
  img
    width: 20px
    height: 20px
    margin-right: 8px
  li
    display: inline-block
    width: 49%
    vertical-align: top

  i,span
    vertical-align: middle
.ico-serv-hotel
  display: inline-block
  width: 20px
  height: 20px
  margin-right: 8px
.internet
  @include bgImageEl("../images/icons-app/hotels/internet.svg")
.parking
  @include bgImageEl("../images/icons-app/hotels/parking.svg")
.meal
  @include bgImageEl("../images/icons-app/hotels/meal.svg")
.shuttle
  @include bgImageEl("../images/icons-app/hotels/shuttle.svg")
.beach
  @include bgImageEl("../images/icons-app/hotels/beach.svg")
.pool
  @include bgImageEl("../images/icons-app/hotels/pool.svg")
.fitness
  @include bgImageEl("../images/icons-app/hotels/fitness.svg")
.busyness
  @include bgImageEl("../images/icons-app/hotels/busyness.svg")
.barber-shop
  @include bgImageEl("../images/icons-app/hotels/barber-shop.svg")
.kids
  @include bgImageEl("../images/icons-app/hotels/kids.svg")
.disabled-support
  @include bgImageEl("../images/icons-app/hotels/disabled-support.svg")
.air-conditioning
  @include bgImageEl("../images/icons-app/hotels/air-conditioning.svg")
.hotel-contacts
  i
    margin-right: 8px

@media (max-width: 576px)
  .images-item-container,
  .images-item-grid
    height: 190px
  .hotel-main-top
    .main-grid
      gap: 8px
  .hotel-address,
  .hotel-from-price
    text-align: left
  [data-controls=prev], [data-controls=next]
    display: none
  .hotel-tabs
    display: none
  .hotel-main-top
    .tag
      margin: 0
      margin-top: 8px
      width: 100%
      text-align: center
