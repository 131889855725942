.chat-rooms
  min-height: 300px
  height: 100%
  // border: 1px solid $grayColor
  // border-radius: 15px
  position: relative
  overflow-y: auto
  border-right: 1px solid $grayColor
.custom-scroll
  &::-webkit-scrollbar
    width: 7px
    border-radius: 30px
  &::-webkit-scrollbar-track
    background: rgba($grayColor, 0.10)

  &::-webkit-scrollbar-thumb
    background-color: $grayColor

.rooms-item
  position: relative
  // border-bottom: 1px solid rgba($blueColor , 0.2 )
  padding: 12px 8px 12px 15px
  cursor: pointer
  width: 100%
  text-decoration: none
  &::after
    content: ''
    @include positionElement(calc(100% - 90px), 1px, 0, 0, top, right)
    background-color: rgba($blueColor , 0.2 )
    transition: ease-out 0.5s
  &:hover,
  &.active
    background-color: rgba($blueColor , 0.1 )
    &::after
      width: calc(100% - 45px)
  .btn-trash
    position: absolute
    top: 27px
    right: 0
    margin-right: 20px

  a
    display: block
    width: 100%
.rooms-item-face
  width: 60px
  height: 60px
  position: relative
  border-radius: 100%
  vertical-align: middle
  display: inline-block
  margin-left: 5px
.rooms-item-name
  font-weight: 700
  display: block
.rooms-item-message
  // color: $blueColor
  i
    margin-right: 8px
  .bokal-ico--white
    background-image: url("../images/icons-app/tags/bokal.svg")
  .padushka-ico--white
    background-image: url("../images/icons-app/tags/padushka.svg")
  .plag-ico--white
    background-image: url("../images/icons-app/tags/plag.svg")
  .pribor-ico--white
    background-image: url("../images/icons-app/tags/pribor.svg")
.rooms-item-info
  // text-align: right
  padding-left: 15px
  width: calc( 100% - 106px )
  display: inline-block
  vertical-align: middle

.rooms-item-count
  display: inline-block
  margin-left: 15px
.rooms-item-date
  font-size: 12px
  color: $grayColor
  color: rgba(58, 80, 107, 0.6)
.rooms-item-date,
.rooms-item-online
  font-size: $smallSize
  margin: 0
  display: inline-block

.rooms-item-count
  @include positionElement(18px, 18px, -15px, 3px)
  text-align: center
  color: $whiteColor
  background-color: $blueColor
  border-radius: 100%
  font-weight: 700
  font-size: 12px
  margin: 0

.rooms-item-online
  font-weight: 700
  color: $blueColor
// .room-item-message
//   font-size: $smallSize
//   color: $grayColor
.message-head
  padding: 0 0 9px
  &:hover
    background: none
  &::after
    background-color: rgba(58, 80, 107, 0.2)
    width: 100%
.empty-room
  text-align: center
  height: 585px
  display: flex
  h3
    font-weight: 400
    font-size: 18px
    margin: 0 0 26px
    color: $blackColor
  .empty-room-container
    margin: auto
    max-width: 290px
  .btn
    width: 260px

@media (max-width: 576px)
  .chat-rooms
    min-height: 180px
    margin-bottom: 15px
    padding: 0
