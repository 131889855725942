/*-checkbox-start-*/

.checkbox,
.radio
	display: inline-block
	position: relative
	padding: 5px
	padding-left: 35px
	cursor: pointer
	user-select: none
	vertical-align: top
	label
		cursor: pointer
	&:last-child
		margin-right: 0
	input
		margin: 0
		@include positionElement(95%, 100%)
		opacity: 0

		&:checked~span
			background-color: $blueColor
			border-color: $blueColor
			&:after
				display: block

	span
		@include positionElement(20px, 20px, 0px, 5px)
		border: 1px solid $grayColor
		transition: ease-out 0.5s
		cursor: pointer
		border-radius: 100%
		&:after
			content: ""
			@include positionElement(10px, 10px, 4px,4px)
			background-color: $whiteColor
			border-radius: 100%

			display: none
.agreement

	span
		@include positionElement(21px, 21px, 0px, 5px)
		background-color: $blueColor
		border-radius: 0%
		border: 0
		&:after
			content: ""
			background: none
			@include positionElement(100%, 100%, 0%,0%)
			@include bgImageEl("../images/icons/agree-lite-white.svg")
	label
		text-transform: initial
		font-weight: 500
/*-checkbox-wp-*/
